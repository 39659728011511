@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
    display: block;
    cursor: pointer;
}
.card:hover {
    background-color: rgba(99, 246, 236, 0.1);
}

.card.correct {background-color: rgb(32, 224, 32);}
.card.incorrect {background-color: rgb(239, 27, 27);}

.footer-fix {
    min-height: 100vh;            /* fall-back */
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
}